import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import CarouselMiniatures from "../carousel/carousel-with-miniatures";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { Button } from "gatsby-theme-material-ui";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { formatCurrencyString } from "use-shopping-cart";
import { useShoppingCart } from "use-shopping-cart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox } from "@mui/material";
import BenefitsSectionMicroREC from "./benefits";
import "../../../static/microrec.css";
import useStripeProducts from "../products/useStripeProducts";

const ProductSectionMicroREC = ({ connect, ...props }) => {
  const { addItem, cartDetails } = useShoppingCart();
  const [open, setOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      carrouselImagesMicroREC: allFile(
        filter: { relativePath: { regex: "/ecommerce/microREC/" } }
        sort: { fields: relativePath }
      ) {
        nodes {
          childrenImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const carrouselImagesMicroREC = data.carrouselImagesMicroREC;

  const products = useStripeProducts();

  const microRECStripeArray = products.filter((product) => {
    return (
      product &&
      product.name &&
      product.name.includes("MRC") &&
      product.ecommercePrice &&
      product.ecommerceBlackFriday == null
    );
  });

  const microRECStripe = microRECStripeArray[0];

  const handleClick = () => {
    handleAddItem();
    handleBackDrop();
    setTimeout(() => {
      link2UpsellPage();
    }, 400);
  };

  const handleAddItem = () => {
    addItem(microRECStripe);
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  const link2UpsellPage = () => {
    navigate("/store/adapter/microrec/software", {});
  };

  return (
    <section
      id="main_MicroREC_section"
      style={{ marginTop: 10, marginBottom: 0 }}
    >
      <Container maxWidth={"lg"}>
        <Grid container justifyContent={"center"}>
          <Grid item sm={6}>
            <Typography variant="h6" align={"center"} gutterBottom={true}>
              Our Adapters specially designed for the MicroREC. If you still
              don't have it, you can add it to your cart below.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          marginTop={{ xs: 2 }}
        >
          <Grid item xs={11} sm={5}>
            <CarouselMiniatures data={carrouselImagesMicroREC.nodes} />
            <BenefitsSectionMicroREC />
          </Grid>
          <Grid
            container
            item
            xs={11}
            sm={6}
            marginTop={{ xs: 2, sm: 0 }}
            marginBottom={{ xs: 2, sm: 0 }}
            spacing={1}
            justifyContent={"space-between"}
            sx={{ alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align={"left"}
                gutterBottom={true}
                sx={{ fontWeight: "bold", marginTop: "1em" }}
              >
                MicroREC
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom={true}>
                <b style={{ fontWeight: "400", fontSize: "16px" }}>from</b>{" "}
                <b>
                  {formatCurrencyString({
                    value: microRECStripe.price,
                    currency: microRECStripe.currency,
                  })}{" "}
                </b>
              </Typography>
              <b style={{ fontWeight: "400", fontSize: "14px" }}>
                VAT not included*
              </b>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph={true}
                gutterBottom={true}
                sx={{
                  textAlign: "justify",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                MicroREC is an optical system with German lenses that allows you
                to connect your smartphone to your microscope or slit lamp.
              </Typography>{" "}
            </Grid>
            <Grid item xs={12}>
              <hr></hr>

              <div style={{ paddingTop: "1em" }}>
                <a
                  href="/microrec"
                  target="_blank"
                  style={{
                    color: "#838383",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Learn more
                </a>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              justifyContent={"center"}
              sx={{ textAlign: "center" }}
            >
              <Button
                onClick={handleClick}
                color="primary"
                variant="contained"
                endIcon={<AddShoppingCartOutlinedIcon />}
                sx={{
                  padding: "8px 30px",
                  marginTop: "1em",
                  borderRadius: "10px",
                  color: "white",
                }}
              >
                Add to Cart
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ textAlign: "center" }}>
          {" "}
          <button
            onClick={link2UpsellPage}
            style={{
              backgroundColor: "transparent",
              border: "none",
              marginTop: "2em",
            }}
          >
            {" "}
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
            />
            I don't need the MicroREC at the moment.
          </button>
        </div>
      </Container>
    </section>
  );
};

export default ProductSectionMicroREC;
